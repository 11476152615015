import React from 'react'

const ProjectComingSoonTriangles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="156"
    height="208"
    viewBox="0 0 156 208"
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#B9BFC8" d="M52 156h104l-52 52z" />
      <path
        fill="#EFEFEF"
        d="M104 52v104L0 52zM0 156h52L0 208zM104 52V0l52 52z"
      />
    </g>
  </svg>
)

export { ProjectComingSoonTriangles }
