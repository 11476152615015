import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'
import { ProjectComingSoonTriangles } from './ProjectComingSoonTriangles';

const ProjectComingSoon = props => {
  const { color } = props

  return (
    <div className={style.projectComingSoonContainer}>
      <div className={style.projectComingSoon}>
        <p style={{ backgroundColor: color, opacity: 0.7 }}>
          Projeto completo em breve!
        </p>
      </div>
      <ProjectComingSoonTriangles />
    </div>
  )
}

ProjectComingSoon.propTypes = {
  color: PropTypes.string.isRequired
}

export default ProjectComingSoon
