import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectComingSoon from '../components/ProjectComingSoon'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/apartamento-104-nw/header-right-banner.jpg'
import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Apartamento',
  subTitle: '104nw',
  typeService: 'PROJETO',
  duration: '03 meses',
  deliverDate: '2018',
  local: 'Brasília',
  color: '#46b7d8',
  description: (
    <Fragment>
      <p>
        Projeto de reforma de apartamento entregue pela construtora. Fizemos
        nesse apartamento de 3 quartos, poucas intervenções de layout, mas
        muitas mudanças de decoração e marcenaria para que o apartamento fosse,
        de fato, a <b>materialização do sonho</b> dos clientes.
      </p>
      <p>
        Criamos um closet na suíte do casal e um home theater com portas
        deslizantes que se integram com a sala para dar opções de uso para os
        proprietários. Nesse caso, também executamos a obra em
        <b>modalidade Administração Completa</b>, ou seja, os clientes entregam
        a chaves e recebem o apartamento pronto pra mudança
      </p>
    </Fragment>
  ),
  nextProject: 'cozinha-rj'
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Apartamento 104 NW"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectComingSoon color={dynamicInfos.color} />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
